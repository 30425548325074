

:root {
    --bs-success: #1CBB82;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

.navbar-brand {
    font-family: poppins;
}

.copyright {
    text-align:right;
    margin-right:100px;
}
.navbar-toggler {
    background-color: transparent !important;
  }
  .custom-color{
    color: #5d78ff !important;
  }
  .open .custom-color {
    color: #5d78ff !important;
    transform: rotate(180deg);
  }

.readfirst {
    height:600px;

}
.empty {
    padding-top:20px;
    padding-bottom:20px;
}

.sidebar-new {
    left:0;
    margin-left:0;
    border:0;
    bottom: 0;
    margin-top: 47px;
    box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14),0 3px 1px -2px rgba(var(--cui-elevation-base-color,60,75,100),.12),0 1px 5px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2);
    position: fixed;
    top: 0;
    z-index: 1030;
    border-bottom: 1px solid #252c41;
    border-right: 1px solid #252c41;    

    --cui-sidebar-width: 12rem;
    background: #131722;
    box-shadow: none;
    color: hsla(0,0%,100%,.87);
    color: var(--cui-sidebar-color,hsla(0,0%,100%,.87));
    display: flex;
    flex: 0 0 var(--cui-sidebar-width);
    flex-direction: column;
    order: -1;
    padding: 0;

    transition: margin-left .15s,margin-right .15s,box-shadow 75ms,width .15s,z-index 0s ease .15s,-webkit-transform .15s;
    transition: margin-left .15s,margin-right .15s,box-shadow 75ms,transform .15s,width .15s,z-index 0s ease .15s;
    transition: margin-left .15s,margin-right .15s,box-shadow 75ms,transform .15s,width .15s,z-index 0s ease .15s,-webkit-transform .15s;
    width: var(--cui-sidebar-width);
}

.sidebar-brand {
    display: flex!important;
    padding-right:20px;
    font-size: 1.3rem;
    font-family: poppins;
    font-weight:500;
    align-items: center;
    
    --cui-sidebar-brand-color: rgb(93, 120, 255);
    color: var(--cui-sidebar-brand-color,0);
    display: flex;
    flex: 0 0 4rem;
    justify-content: center;
    border-bottom: 1px solid #252c41;
    
}

.header-items{
    /* display: flex!important; */
    padding-left:10px;
    font-size: 1.2rem;
    font-family: poppins;
    font-weight:500;
    /* align-items: center; */ 
    padding-top: 15px;
    margin-top: 5rem;
    margin-bottom: 5px;
    --cui-sidebar-brand-color: rgb(93, 120, 255);
    color: var(--cui-sidebar-brand-color,0);
    /* display: flex; */
    flex: 0 0 4rem;
    justify-content: center;
    /* border-bottom: 1px solid #252c41; */
}

.lightgrey {
    color:#aaa;
}

.content-img {
    margin-top:24px;
    margin-bottom:24px;
    box-shadow: 0 4px 8px 0 #4b4b4bab, 0 6px 20px 0 #4b4b4bab;
}

.apps-content ul li {
  padding: 4px 0;
  font-size: 14px; 
}

.apps-content ul li i {
    font-weight: bold;
    font-size: 14px;
    color: #5d78ff;
    margin-right: 16px; 
}

.active-link {
    background-color: #5d78ff;
}
.dashboard-text{
    float:right;
 }
.content-body {
    margin:8px;
    /* margin-left: 65px; */
  /* margin-top: 18px; */
  /* margin-right: 60px; */
  margin-bottom: 17px;
}

.content-text {
    color: #aaa;
    font-size:14px;
    line-height:1.6em;
}
.content-title {
    padding-left:36px;
}

.content-container {
    padding-left:36px;
}

.header-new {
    border: 0px;
    box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14),0 3px 1px -2px rgba(var(--cui-elevation-base-color,60,75,100),.12),0 1px 5px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1029;
    margin-bottom: 0.2rem!important;
    align-items: center;
    background: #131722;
    border-bottom: 1px solid #01081b;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 3rem;
    padding: .1rem;
    position: relative;
}



.navbar {
    padding:0px;
}

.menu ul li {
    margin-bottom:0px;
    margin-left:8px;
    text-align:left;

}


.menu ul li a {
    padding:0px;
    margin-bottom:0px;
    border-radius: 0px;
    display:block;
}

.nav-title {
    color: hsla(0,0%,100%,.6);
    font-size: 100%;
    font-weight: 700;
    margin-top: 1rem;
    padding: .75rem 1rem;
    
    text-align: left;
}
.nav-item {
    align-items: center;
    background: transparent;
    background: var(--cui-sidebar-nav-link-bg,transparent);
    color: hsla(0,0%,100%,.6);
    color: var(--cui-sidebar-nav-link-color,hsla(0,0%,100%,.6));
    display: flex;
    flex: 1 1;
    text-decoration: none;
    white-space: nowrap;
    font-size:0.8rem;
}

.menu ul li a i {
    font-size:12px;
    vertical-align: middle;
}
.mdi {
    padding:8px;
    margin-left:25px;
    font-size: 12px;
    vertical-align: middle;
}
#main-wrapper {
    margin-top:0px;
}
.wrapper {
    --cui-sidebar-occupy-start: 12rem;
    padding-left: 0;
    /* padding-left: var(--cui-sidebar-occupy-start,0); */
    --cui-bg-opacity: 1;
    background-color: transparent!important;
    transition: padding .15s;
    width: 100%;
    will-change: auto;
    flex-direction: column!important;
    display: flex!important;
    margin:0;
}

.sidebar-open {
    padding-left: var(--cui-sidebar-occupy-start, 0);
  }

.menu {
    margin-top:3px;
    text-align:left;
    border-top: 1px solid rgb(54 60 79);


}

.section-padding {
    padding:0px 8px 0px 8px;
}